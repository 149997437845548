import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';
import { map } from 'rxjs/operators';
import { CmuDeptnoteApiParams } from 'src/app/model/cmu-dept-note-api-params.model';
import { DebtnoteDocumentApprove } from 'src/app/model/debtnote-document-approve.model';
import { RegisterDocumentApprove } from 'src/app/model/register-document-approve.model';

@Injectable()
export class ApproveDocumentService {
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}
    getMaxDocumentApprove(year: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/DocumentApprove`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // api/Debtnote/Organization/{OrganizationId}/{year}/start/{startDate}/end/{endDate}/status/{docstatus}/Approve
    getDebtnoteDocumentApproveByTime(OrganizationId: string, year: number, startDate: string , endDate: string , docstatus: number ) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${OrganizationId}/${year}/start/${startDate}/end/${endDate}/status/${docstatus}/Approve`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getDebtnoteDocumentApproveForOrgReceiveByOrg(OrganizationId: string, year: number, receivenumbers: string ) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${OrganizationId}/${year}/receivenumbers/${receivenumbers}/DocumentApprove`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getbebtnoteDocumenApproveById(year: string , debtnoteId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/year/${year}/${debtnoteId}/Approve`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // api/Debtnote/Organization/{OrgCodeId}/{year}/receiveNumbers/{receivenumbers}/status/{docstatus}/approves
    getDebtnoteDocumentApproveByOrgReceiveNumber(OrganizationId: string, year: number, receivenumbers: number  , docstatus: number ) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${OrganizationId}/${year}/receivenumbers/${receivenumbers}/status/${docstatus}/approve`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getRegisDocumentApproveByOrganizationId(year: number, regisDocId: string, OrganizationId: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/year/${year}/${regisDocId}/Organization/${OrganizationId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    insertDebtnoteDocumentApprove(debtnoteDocumentApprove: DebtnoteDocumentApprove[]) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/DocumentApprove`;
        const body = new CmuDeptnoteApiParams( debtnoteDocumentApprove , '');
        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    updateDebtnoteDocumentApprove(debtnoteDocumentApprove: DebtnoteDocumentApprove) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/DocumentApprove/Update`;
        const body = new CmuDeptnoteApiParams( debtnoteDocumentApprove , '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    // tslint:disable-next-line:max-line-length
    insertDebtnotepproveByGroup(OrganizationId: string, docyear: number, docstartDate: string , docendDate: string , docstatusApprove: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/DebtnoteApproveGroup`;
        const body = new CmuDeptnoteApiParams( {
            OrgCodeId: OrganizationId,
            year: docyear,
            startDate: docstartDate,
            endDate: docendDate ,
            docstatus: docstatusApprove
        } , '');
        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    insertRegisterDocumentApprove( regisDocumentApprove: RegisterDocumentApprove[]) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/RegisterDocumentApprove`;
        const body = new CmuDeptnoteApiParams( regisDocumentApprove , '');
        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    // api/Debtnote/DocumentApprove/Update

    // editDebtnoteReply(deptnote: Deptnote) {
    //     const url = `${this.config.app.api.apiEndpoint}EditDebtnotetoReply`;
    //     const body = new CmuDeptnoteApiParams(deptnote, '');

    //     return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    // }
}
