import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';



@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class LogoutComponent implements OnInit {

    constructor(
         private router: Router
    ) { }

    ngOnInit(): void {
        // this.authenticationService.logout();
        localStorage.removeItem('currentUser');
        localStorage.removeItem('RoleUser');
        localStorage.removeItem('jwtToken');
        // window.location.href  = '/login' ;
         // tslint:disable-next-line:align
         this.router.navigate(['/login']);
    }
}
