
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmuDeptnoteApi } from '../model/cmu-dept-note-api.model';
import { CmuDeptnoteApiParams } from '../model/cmu-dept-note-api-params.model';
import { AppConfig , APP_CONFIG} from '../app-config.module';


@Injectable()
export class HomeService {
  constructor(
      private httpClient: HttpClient,
      @Inject(APP_CONFIG) private config: AppConfig
    ) { }
    private showDialogSource = new Subject<any>();
  getJWTToken(authorizationCode: string): Observable<CmuDeptnoteApi> {
    const url = `${this.config.app.api.apiEndpoint}getJWTToken/`;
    // console.log(url);
    const body = new CmuDeptnoteApiParams({ code: authorizationCode }, '');


    return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
  }
  showDialog(message: string, title?: string) {
    const dialogProperties = {
      message: [ message ] ,
      title: title || 'เกิดข้อผิดพลาด'
    };
    this.showDialogSource.next(dialogProperties);
  }
  getDebtnoteHistoryList(OrganizationID: string, receiveno: string, debtnoteId: number) {
    const url = `${this.config.app.api.apiEndpoint}Debtnote/${OrganizationID}/${receiveno}/${debtnoteId}/History`;
    return this.httpClient
    .get<CmuDeptnoteApi>(url)
    .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
  }
  getDebtnoteSendHistoryList(OrganizationID: string, docYear: string, debtnoteId: number) {
    const url = `${this.config.app.api.apiEndpoint}Debtnote/${OrganizationID}/${docYear}/Send/${debtnoteId}/History`;
    return this.httpClient
    .get<CmuDeptnoteApi>(url)
    .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
  }
  getDocumentAmountAllStatus( docYear: string){
    const url = `${this.config.app.api.apiEndpoint}Debtnote/Receive/year/${docYear}/Amount`;
    return this.httpClient
    .get<CmuDeptnoteApi>(url)
    .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
  }
}
