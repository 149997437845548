import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';
import { CmuDeptnoteApiParams } from 'src/app/model/cmu-dept-note-api-params.model';
import { ControlDocumentNumber } from 'src/app/model/controldocumentnumber.model';
import { DebtnoteDocumentReturn } from 'src/app/model/debtnote-documetn-return.model';
import { RegisterDocumentApprove } from 'src/app/model/register-document-approve.model';
import { DocumentReturnBox } from 'src/app/model/document-return-box.model';

@Injectable()
export class ReturnDocumentService {
    apiEndpoint = '';
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        this.apiEndpoint = `${this.config.app.api.apiEndpoint}`;
    }
    GetReportSegmentIncomeSummary(
    FileType: string,
    Year: string,
    ReceiveNumber: string
    ): Observable<Blob> {
    const url = `${this.apiEndpoint}report/GetReportDocumentCover`;
    const queryString =
        `?FileType=${FileType}` +
        `&year=${Year}` +
        `&receiveNumber=${ReceiveNumber}`;
    return this.httpClient
        .get(url + queryString, { responseType: 'blob' })
        .pipe(map(data => data));
    }
    UpdateReceiveDocReturn(deptnoteId: number,  userReceive: string, receiveDate: Date, docStatus: number, docdetailId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Return`;
        const body = new CmuDeptnoteApiParams( {
            debt_note_id: deptnoteId,
            doc_detail_id: docdetailId,
            user_receive: userReceive,
            date_receive : receiveDate,
            doc_Status: docStatus
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    // tslint:disable-next-line:max-line-length
    UpdateReceiveDocReturnApprove(organizationId: string,  userReceive: string, receiveDate: Date, dateApproveGroup: string, docYear: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReturnDocumentApprove`;
        const body = new CmuDeptnoteApiParams( {
            organization_id: organizationId,
            user_receive: userReceive,
            date_receive : receiveDate,
            date_approve_Group: dateApproveGroup,
            doc_year: docYear
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    // tslint:disable-next-line:max-line-length
    UpdateReceiveRegisterDocReturnNormal(organizationId: string,  userReceive: string, receiveDate: Date, dateApproveGroup: string, docYear: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReturnRegisterDocumentNormal`;
        const body = new CmuDeptnoteApiParams( {
            organization_id: organizationId,
            user_receive: userReceive,
            date_receive : receiveDate,
            date_approve_Group: dateApproveGroup,
            doc_year: docYear
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UpdateReceiveDocReturnNorMal(regisDocId: string, years: string , userReceive: string, receiveDate: Date) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReturnDocumentNormal`;
        const body = new CmuDeptnoteApiParams( {
            regis_doc_id: regisDocId,
            // doc_detail_id: docdetailId,
            yesr: years ,
            user_receive: userReceive,
            date_receive : receiveDate,
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    //Debtnote/ReturnDocumentBox
    UpdateReceiveDocReturnBox(orgId:string,userReceive:string,dateReceive:string,dateApproveGroup:string, docYear:number,
        docAmounApprove:number,docAmountEdit:number,docAmount_Normal:number,docAmountCencel:number,docDetailId:number,debtNoteId:number,cenceldocDetailId:number,cenceldebtNoteId:number){
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReturnDocumentBox`;
        const body = new CmuDeptnoteApiParams( {
            organization_id:orgId,
            user_receive:userReceive,
            date_receive:dateReceive,
            date_approve_Group:dateApproveGroup,
            doc_year:docYear,
            doc_amount_approve:docAmounApprove,
            doc_amount_edit:docAmountEdit,
            doc_amount_normal:docAmount_Normal,
            doc_amount_cencel:docAmountCencel,
            edit_doc_detail_id:docDetailId,
            edit_debt_note_id:debtNoteId ,
            cencel_doc_detail_id:cenceldocDetailId,
            cencel_debt_note_id:cenceldebtNoteId 
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    uploadFile(formData) {
        return this.httpClient.post('https://example.com/upload.php', formData);
    }
    UploadSignature(deptnoteId: number,  base64Data: string, docdetailId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SignatureUpload`;
        const body = new CmuDeptnoteApiParams( {
            debt_note_id: deptnoteId,
            base64ToData: base64Data,
            doc_detail_id: docdetailId
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UploadSignatureApprove(deptnoteId: number,  base64Data: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SignatureUploadApprove`;
        const body = new CmuDeptnoteApiParams( {
            debt_note_id: deptnoteId,
            base64ToData: base64Data
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UploadSignatureApproveGroupByDate( debtnoteApproveReturn: DebtnoteDocumentReturn[],  base64Data: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SignatureUploadApproveGroupByDate`;
        const body = new CmuDeptnoteApiParams( {
            DebtnoteDocumentReturn: debtnoteApproveReturn,
            base64ToData: base64Data
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UploadSignatureRegisterDocumentApproveGroupByDate( registerDocumenteApproveReturn: RegisterDocumentApprove[],  base64Data: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SignatureUploadRegisterDocumentApproveGroupByDate`;
        const body = new CmuDeptnoteApiParams( {
            RegisterDocumenteApproveReturn: registerDocumenteApproveReturn,
            base64ToData: base64Data
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UploadSignatureNormal(regisDocId: string,  base64Data: string, years: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SignatureUploadNormal`;
        const body = new CmuDeptnoteApiParams( {
            regis_doc_id: regisDocId,
            year: years,
            base64ToData: base64Data
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UploadSignatureCancel(deptnoteId: number,  base64Data: string, years: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SignatureUploadCancel`;
        const body = new CmuDeptnoteApiParams( {
            debt_note_id: deptnoteId,
            base64ToData: base64Data
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UploadSignatureRevolk(deptnoteId: number,  base64Data: string, docdetailId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SignatureUploadRevolk`;
        const body = new CmuDeptnoteApiParams( {
            debt_note_id: deptnoteId,
            base64ToData: base64Data,
            doc_detail_id: docdetailId
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UploadSignatureDocumentReturnBox( documentReturnBox: DocumentReturnBox[],  base64Data: string,years: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SignatureUploadDocumentReturnBox`;
        const body = new CmuDeptnoteApiParams( {
            DocumenteReturnBox: documentReturnBox,
            base64ToData: base64Data,
            year: years
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    UpdateControlNumber(debtnoteId: number, docdetailId: number, controlNumberId: number, year: number, dacStatus: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ControlNumber`;
        const body = new CmuDeptnoteApiParams( {
            debt_note_id: debtnoteId,
            doc_detail_id: docdetailId,
            control_number_id: controlNumberId,
            doc_year: year ,
            doc_status: dacStatus
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }

    GetSignatureUpload(debtNoteId: number, debtDetailId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Signature/${debtNoteId}/${debtDetailId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     GetSignatureUploadAllApproveByDate(orgMISId: string, year: number, dateApproveGroup: string, groupApproveID: number) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Signature/${orgMISId}/${year}/Date/${dateApproveGroup}/Group/${groupApproveID}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     GetSignatureUploadAllNormalApproveByDate(orgMISId: string, year: number, dateApproveGroup: string, groupApproveID: number) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Signature/${orgMISId}/${year}/Date/${dateApproveGroup}/Group/${groupApproveID}/Normal`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     GetSignatureUploadNormal(regisDocId: string, yearId: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Signature/${regisDocId}/Normal/${yearId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     //api/Debtnote/Signature/{_orgId}/{_year}/Date/{_dateApproveGroup}/approve/{_groupApproveId}/doc/{_groupDocId}/edit/{_docDetailId}/cancel/{_docDetailCancelId}/ReturnBox
     GetSignatureUploadDocumentReturnBox(_orgId:string,_year:number,_dateApproveGroup:string,_groupApproveId:number,_groupDocId:number,_docDetailId:number,_docDetailCancelId:number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Signature/${_orgId}/${_year}/Date/${_dateApproveGroup}/approve/${_groupApproveId}/doc/${_groupDocId}/edit/${_docDetailId}/cancel/${_docDetailCancelId}/ReturnBox`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     GetReturnDeptnoteByOrgList(orgzniationId: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${orgzniationId}/ReturnDebtnoteList`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     GetMaxControlDocumentNumberId(year: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/MaxControlDocumentNumberID`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     addControlDocumentNumber(controlnumber: ControlDocumentNumber) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ControlDocumentNumber`;
        const body = new CmuDeptnoteApiParams(controlnumber, '');

        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    receiveReturnDocument(debtnoteDocumentReturn: DebtnoteDocumentReturn) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReceiveReturnDocument`;
        const body = new CmuDeptnoteApiParams(debtnoteDocumentReturn, '');

        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    getRegisterDocumentNormal(year: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/RegisterDocumentNormal`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getRegisterDocumentNormalById(year: string, Id: string, toId: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/RegisterDocumentNormal/${Id}/to/${toId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getApproveDocumentNormalById(documentNumber: string, organizationId: string, year: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${documentNumber}/${organizationId}/SearchApprove/${year}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // tslint:disable-next-line:max-line-length
    getApproveDocumentApproveByOrganizationId(receiveNumber: string, organizationId: string, year: string , DateApprove: string) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${receiveNumber}/${organizationId}/SearchApprove/${year}/Date/${DateApprove}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getRegisterDocumentNormalApproveById(regisDocId: string, organizationId: string, year: string , DateApprove: string) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${regisDocId}/${organizationId}/SearchRegisterDocument/${year}/Date/${DateApprove}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getApproveDocumentApproveByOrganizationList( organizationId: string, year: string , DateApprove: string, groupApproveId: number) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${organizationId}/SearchApprove/${year}/Date/${DateApprove}/Group/${groupApproveId}/List`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getRegisterDocumentApproveByOrganizationList( organizationId: string, year: string , DateApprove: string, groupApproveId: number) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${organizationId}/SearchRegisterDocument/${year}/Date/${DateApprove}/Group/${groupApproveId}/List`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // api/Debtnote/ReceiveNo/{ReceiveNo}/Year/{Year}/SearchReceiveNo
    getCancelDocumentNormalById(documentNumber: string, year: string, controlNumberId: string) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReceiveNo/${documentNumber}/ControlNumber/${controlNumberId}/SearchReceiveNo/${year}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getCancelDocumentNormalApproveById(documentNumber: string, year: string, controlNumberId: string, DateApprove: string) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReceiveNo/${documentNumber}/ControlNumber/${controlNumberId}/SearchReceiveNo/${year}/Date/${DateApprove}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getReturnDeptnoteByDocNumber(docNumber: string , OrganizationID: string, controlNumber: string, year: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${docNumber}/${OrganizationID}/${controlNumber}/SearchReceiveNo/${year}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getLastMessage() {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/LastMessage`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    deleteControlNumber(controlNumber: number, dcoDebtId: number, years: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/DeleteDebtnote/${controlNumber}/${dcoDebtId}/year/${years}`;
        return this.httpClient.delete<CmuDeptnoteApi>(url).pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }

    sendNotifycationMail(years: number, documentNumber: string, organization: string, titleAlter: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/NotificationMail`;
        const body = new CmuDeptnoteApiParams( {
            year: years,
            receiveNumber: documentNumber,
            organizationId: organization,
            title: titleAlter
        }, '');

        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    GetDocumentReturnBoxByOrg(_orgId: string, _year: string, _controlNumberId: string, _docId: string,_dateApprovrGroup: string ){
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${_orgId}/${_controlNumberId}/doc/${_docId}/Date/${_dateApprovrGroup}/year/${_year}/returnBox/`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // Debtnote/Organization/{_orgId}/doc/{_docApproveId}/{_docEditId}/{_documentId}/{_docCencelId}/Date/{_dateApprovrGroup}/year/{_year}/detailBox
    GetDocumentReturnBoxInOrgAllStatus(_orgId: string, _year: number,_dateApprovrGroup: string, _docApproveId: number, _docEditId: number, _documentId:number,_docCencelId:number){
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${_orgId}/approve/${_docApproveId}/edit/${_docEditId}/Id/${_documentId}/cencel/${_docCencelId}/Date/${_dateApprovrGroup}/year/${_year}/detailBox/List`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // thumbnailFetchUrl: string;
     // getBlobThumbnail(): Observable<Blob> {
    //     this.thumbnailFetchUrl = 'https://south/generateThumbnail?width=100&height=100&smartCropp';
    //     const headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         // tslint:disable-next-line:object-literal-key-quotes
    //         'Accept': 'application/json'
    //     });
    //     return this.httpClient.post<Blob>(this.thumbnailFetchUrl,
    //         // tslint:disable-next-line:object-literal-shorthand
    //         { 'url': 'http://acs/container/Logo.png' }, { headers: headers , responseType: 'blob' as 'json' }
    //     );
    // }
}
