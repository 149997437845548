import { HttpClient } from '@angular/common/http';
import {Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';
import { CmuDeptnoteApiParams } from 'src/app/model/cmu-dept-note-api-params.model';
import { Deptnote } from 'src/app/model/deptnote.model';

@Injectable()
export class AddDocumentSendService {
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}
    getyear() {
        const url = `${this.config.app.api.apiEndpoint}year/all`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getOrganizationAll() {
        const url = `${this.config.app.api.apiEndpoint}organization/all`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getSubjectTypes() {
        const url = `${this.config.app.api.apiEndpoint}subjecttype/all`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));

    }
    getOrganizationGroup(OrganizationId: string) {
        const url = `${this.config.app.api.apiEndpoint}organizationGroup/${OrganizationId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getOrganizationGroupAll() {
    const url = `${this.config.app.api.apiEndpoint}organizationGroup/all`;
    return this.httpClient
      .get<CmuDeptnoteApi>(url)
      .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getOrganizationGroupById(OrgGroupId: string, OrganizationId: string) {
        const url = `${this.config.app.api.apiEndpoint}organizationGroupById/${OrgGroupId}/${OrganizationId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getOrganizationGroupByGroupId(OrgGroupId: string) {
        const url = `${this.config.app.api.apiEndpoint}organizationGroupById/${OrgGroupId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getdebtnoteById(debtnoteId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${debtnoteId}/Get`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    addDeptnote(deptnote: Deptnote) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote`;
        const body = new CmuDeptnoteApiParams(deptnote, '');

        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    addDebtnoteOffice(deptnote: Deptnote) {
        const url = `${this.config.app.api.apiEndpoint}DebtnoteOffice`;
        const body = new CmuDeptnoteApiParams(deptnote, '');

        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    editDebtnoteReply(deptnote: Deptnote) {
        const url = `${this.config.app.api.apiEndpoint}EditDebtnotetoReply`;
        const body = new CmuDeptnoteApiParams(deptnote, '');

        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }

}
