import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable()
export class HomeHttpInterceptor implements HttpInterceptor {
    constructor(
        private router: Router
    ) {}
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const jwtToken = localStorage.getItem('jwtToken') || '';

        const newRequest = req.clone({
          headers:  req.headers.set('Authorization', 'Bearer ' + jwtToken)
        });

        // return next.handle(newRequest).do(
        //     () => {},
        //     err => {
        //         if (err.status === 401) {
        //             this.router.navigate(['/login']);
        //         }
        //         return Observable.throw(err);
        //     }

        // );

        return next.handle(newRequest).pipe(
            catchError((error: any) => {
                if (error.status === 401) {
                    this.router.navigate(['/login']);
                }
                return throwError(error);
            })
        );
    }
}
