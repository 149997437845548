import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localeTh from '@angular/common/locales/th';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideMenuComponent } from './ui/side-menu/side-menu.component';
import { HeaderComponent } from './ui/header/header.component';
import { BodyComponent } from './ui/body/body.component';
import { HomeFacultyService } from './page/home-faculty/home-faculty.service';

import { HttpClientModule } from '@angular/common/http';
import { AppConfigModule } from './app-config.module';
import { DropdownModule } from 'primeng/dropdown';

import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';

import { LayoutContentComponent } from './layout/layout-content/layout-content.component';
import { LoginComponent } from './auth/login/login.component';


import { OAuthComponent } from './auth/oauth/oauth.component';
import { OAuthService } from './auth/oauth/oauth.service';
import { HomeHttpInterceptor } from './page/home.interceptor';
import { HomeService } from './page/home.service';
import { AddDocumentSendService } from './page/add-document-send/add-document-send.service';
import { AppSideMenuFacultyComponent } from './ui/side-menu-faculty/side-menu-faculty.component';
import { AppSideMenuAdminComponent } from './ui/side-menu-admin/side-menu-admin.component';
import { AppSideMenuStaffComponent } from './ui/side-menu-staff/side-menu-staff.component';
import { RegistrationService } from './page/registration/registration.service';

import { MessageService } from 'primeng/api';
import { AcceptDocumentService } from './page/accept-document/accept-document.service';
import { LoginAdminComponent } from './auth/login-admin/login-admin.component';
import { LoginAdminService } from './auth/login-admin/login-admin.service';
import { LogoutComponent } from './auth/logout/logout.component';
import { InformationService } from './page/Information/information.service';
import { ReportService } from './page/report/report.service';
import { ReturnDocumentService } from './page/return-document/return-document.service';
import { ReceiveDocumentService } from './page/receive-document/receive-document.service';
import { AccessByPassComponent } from './auth/access-bypass/access-bypass.component';
import { PresentDocumentService } from './page/present-document/present-document.service';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ApproveDocumentService } from './page/approve-document/approve-document.service';
import { DocumentListFacultyService } from './page/document-list-faculty/document-list-faulty.service';


// import { NgxEditorModule } from 'ngx-editor';


registerLocaleData(localeTh, 'th');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideMenuComponent,
    BodyComponent ,
    LayoutContentComponent,
    LoginComponent,
    OAuthComponent,
    AppSideMenuFacultyComponent,
    AppSideMenuAdminComponent ,
    AppSideMenuStaffComponent,
    LoginAdminComponent,
    LogoutComponent,
    AccessByPassComponent,
    ProgressBarComponent

  ],
  imports: [
    AppConfigModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // TableModule,
    // DataTableModule,
    ButtonModule,
    // TabMenuModule,
    MenuModule,
    PanelMenuModule,
    CardModule,
    ProgressSpinnerModule,
    ProgressBarModule
  ],
  providers: [
    HomeFacultyService,
    OAuthService,
    HomeService,
    AddDocumentSendService,
    AcceptDocumentService,
    RegistrationService,
    MessageService,
    LoginAdminService,
    InformationService ,
    ReturnDocumentService ,
    ReportService ,
    ReceiveDocumentService,
    PresentDocumentService,
    ApproveDocumentService,
    DocumentListFacultyService,
    { provide: LOCALE_ID, useValue: 'th' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HomeHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
