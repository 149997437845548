import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutContentComponent } from './layout/layout-content/layout-content.component';
import { LoginComponent } from './auth/login/login.component';
import { OAuthComponent } from './auth/oauth/oauth.component';
import { LoginAdminComponent } from './auth/login-admin/login-admin.component';
import { LogoutComponent } from './auth/logout/logout.component';




const routes: Routes = [
  //  { path: '', loadChildren: './page/home.module#HomeModule' }
  //  { path: 'login', loadChildren: './page/home.module#HomeModule' }
  // { path: 'Home', component: HomeComponent },
  // { path: 'Registration', component: RegistrationComponent },
  // { path: 'DocumentDeptNote', component : DocumentDeptNoteComponent},
  // { path: 'HomeFaculty', component : HomeFacultyComponent } ,
  // { path: 'AddDocumentSend', component : AddDocumentSendComponent } ,
  // { path: '**', redirectTo: '/HomeFaculty', pathMatch: 'full' }
  { path: '' , component: LayoutContentComponent, children: [
    { path: 'home', loadChildren: './page/home.module#HomeModule'},
    { path: 'admin', loadChildren: './page/home.module#HomeModule'}]} ,
  { path: 'login' , component: LoginComponent },
  { path: 'login/:key' , component: LoginComponent },
  { path: 'logout' , component: LogoutComponent},
  { path: 'loginAdmin' , component: LoginAdminComponent },
  // { path: 'AccessByPass/:key' , component: LogoutComponent},
  { path: 'oauth', component: OAuthComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule ]
})
export class AppRoutingModule { }

// export const appComponents = [
//   HomeComponent,
//   RegistrationComponent ,
//   DocumentDeptNoteComponent ,
//   HomeFacultyComponent ,
//   AddDocumentSendComponent
// ];
