import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html'
})

export class HeaderComponent implements OnInit {
    firstName = '';
    constructor(
        public app: AppComponent,
        private router: Router
    ) { this.intiForm();
    }

    ngOnInit() { }
    intiForm() {
        // const userInfoLogin = JSON.parse(localStorage.getItem('userInfo'));
        const currentUserInfo = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUserInfo  == null) {
           this.router.navigate(['./login']);
        } else {
            this.firstName = currentUserInfo.firstname_TH + ' ' + currentUserInfo.lastname_TH ;
        }
    }
}
