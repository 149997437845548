import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { CmuDeptnoteApi } from '../../model/cmu-dept-note-api.model';
import { CmuDeptnoteApiParams } from '../../model/cmu-dept-note-api-params.model';
@Injectable()
export class OAuthService {
    constructor(
        private httpClient: HttpClient,
        @Inject( APP_CONFIG ) private config: AppConfig
    ) { }

    getBasicInfo(token) {
        const url = 'https://misapi.cmu.ac.th/cmuitaccount/v1/api/cmuitaccount/basicinfo';
        return this.httpClient.get(url, { headers: this.jwt(token) })
            .pipe(data => data);
    }

    openOAuthSignInPage() {
        const oauthUrl = this.config.oauth.loginURI
            + 'response_type=code'
            + '&client_id=' + this.config.oauth.clientID
            + '&redirect_uri=' + this.config.oauth.redirectURI
            + '&scope=' + this.config.oauth.scope
            + '&state=xyz';
        window.location.href = oauthUrl;
    }

    private jwt(token) {
        return new HttpHeaders({ Authorization: 'Bearer ' + token });
    }
    // getJWTToken(authorizationCode: string): Observable<CmuDeptnoteApi> {
    //     const url = `${this.config.app.api.apiEndpoint}getJWTToken/`;
    //     const body = new CmuDeptnoteApiParams({ code: authorizationCode }, '');


    //     return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    //   }
}
