import { Component, OnInit } from '@angular/core';
import { LoginAdminService } from '../login-admin/login-admin.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-access-bypass',
    templateUrl: './access-bypass.component.html'
})
export class AccessByPassComponent implements OnInit {
    keyCode: string;
    itaccount: string;
    constructor(
        private loginAdminServive: LoginAdminService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            // tslint:disable-next-line:no-string-literal
            this.keyCode  = params['key'];

            if ( this.keyCode !== undefined) {
                this.itaccount = atob( this.keyCode);
                this.loginAdminServive.verifyLogin(this.itaccount).subscribe(res => {
                    if (res.status.toLowerCase() === 'true') {
                      // console.log(res.data);
                      localStorage.setItem('jwtToken', res.data.JWTToken);
                      localStorage.setItem(
                        'currentUser',
                        JSON.stringify(res.data.BasicInfo)
                      );
                      // localStorage.setItem('UserInfoData', JSON.stringify(res.data.BasicInfo));
                      localStorage.setItem('RoleUser', JSON.stringify(res.data.RoleUser));
                      if (res.data.RoleUser.RoleID === 1) {
                        this.router.navigate(['./home/admin']);
                      } else if (res.data.RoleUser.RoleID === 3) {
                        this.router.navigate(['./home/main']);
                      } else {
                        this.router.navigate(['./home/page']);
                      }
                    } else {
                      this.router.navigate(['./login']);
                    }
                  },
                  error => this.router.navigate(['./login'])
                );
            }
        });

    }
}
