import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';
import { map } from 'rxjs/operators';
import { CmuDeptnoteApiParams } from 'src/app/model/cmu-dept-note-api-params.model';

@Injectable()
export class PresentDocumentService {
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}
    getMaxDocumentNumber(yearId: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/PresentCommand/Year/${yearId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getPresentDocument() {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/PresentCommand`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getPersonPresent() {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/PresonPresent`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // api/Debtnote/PresonPresent/{TempRequest}/{year}/From/{docFromId}/To/{docToId}/No/{CommandNo}
    getPresentCommandDocument(TempRequest: string, year: string, docFromId: string, docToId: string, CommandNo: number  ) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/PresonPresent/${TempRequest}/${year}/From/${docFromId}/To/${docToId}/No/${CommandNo}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // api/Debtnote/DocumentCommand/No/{CommandNo}/{year}
    getCommandDocumentByNo(commandNo: string,  year: string  ) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/DocumentCommand/No/${commandNo}/${year}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    insertCommandDocument( commandno: string, Documentyear: string, receiveStaff: string, receiveDate: string, staffId: string,
        // tslint:disable-next-line:align
        presentDate: string, personforpresentId: string) {
        const url = `${this.config.app.api.apiEndpoint}api/Debtnote/DocumentCommand`;
        const body = new CmuDeptnoteApiParams( {
            commandNo: commandno,
            DocumentYear: Documentyear,
            receive_staff: receiveStaff,
            receive_date: receiveDate,
            present_date: presentDate,
            personforpresent_id: personforpresentId,
            present_to_detail: ''
        }, '');
        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
}
