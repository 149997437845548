import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-side-menu-admin',
    templateUrl: './side-menu-admin.component.html'
})
export class AppSideMenuAdminComponent implements OnInit {
    debtnoteId: number ;
    items: MenuItem[];
    constructor(
        private app: AppComponent
    ) { }

    ngOnInit(): void {
        this.debtnoteId = 0 ;

        this.items = [
            {
                label: 'File',
                items: [{
                        label: 'New',
                        icon: 'pi pi-fw pi-plus',
                        items: [
                            {label: 'Project'},
                            {label: 'Other'},
                        ]
                    },
                    {label: 'Open'},
                    {label: 'Quit'}
                ]
            },
            {
                label: 'Edit',
                icon: 'pi pi-fw pi-pencil',
                items: [
                    {label: 'Delete', icon: 'pi pi-fw pi-trash'},
                    {label: 'Refresh', icon: 'pi pi-fw pi-refresh'}
                ]
            }
        ];
    }
}
