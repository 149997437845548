export class OauthConfig {
    public loginURI: string;
    public redirectURI: string;
    public clientID: string;
    public scope: string;

    constructor(data?: any) {
        if (data) {
            this.loginURI = data.loginURI;
            this.redirectURI = data.redirectURI;
            this.clientID = data.clientID;
            this.scope = data.scope;
        }
    }
}
