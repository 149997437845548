import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { HomeService } from 'src/app/page/home.service';

@Component({
    selector: 'app-side-menu-staff',
    templateUrl: './side-menu-staff.component.html'//,
    // styles:[`a.p-menuitem-link-active {
    //     border-left: 4px solid greenyellow;
    //     outline: none !important;
    //     user-select: none !important;
    // }`]
})
export class AppSideMenuStaffComponent implements OnInit {
    items: MenuItem[] = [];
    notiMain: string;
    yearCurrent:string;
    constructor(
        public app: AppComponent,
        public homeService: HomeService
    ) { }

    ngOnInit(): void { 
        this.notiMain = '2';
        this.items = [
            {
                label: 'รายงานทะเบียนหนังสือส่ง',
                icon: 'pi pi-pw pi-file',
                items: [
                    {label: 'รายงานคืนอนุมัติ', icon: 'pi pi-fw pi-file',routerLink:['/home/InformationReutnApprove'],routerLinkActiveOptions:{exact:true}},
                    {separator: true},
                    {label: 'รายงานคืนแก้ไข', icon: 'pi pi-fw pi-file',routerLink:['/home/InformationReturnEdit'],routerLinkActiveOptions:{exact:true}},
                    {separator: true},
                    {label: 'รายงานคืนบันทึกข้อความ', icon: 'pi pi-fw pi-file',routerLink:['/home/InformationReturnDocument'],routerLinkActiveOptions:{exact:true}},
                    {separator: true},
                    {label: 'รายงานคืนเอกสารเพื่อยกเลิก', icon: 'pi pi-fw pi-file',routerLink:['/home/InformationReturnCancel'],routerLinkActiveOptions:{exact:true}}
                ]
            }
        ];
        this.yearCurrent = (new Date().getFullYear() + 543).toString();
        this.homeService.getDocumentAmountAllStatus(this.yearCurrent).subscribe(
          res => {
            if(res.status == 'true'){
              this.notiMain = res.data;
            }
          }
        )

    }
    activeMenu(event) {
        console.log(event);
        let node;
        if (event.target.classList.contains("p-submenu-header") == true) {
          node = "submenu";
        } else if (event.target.tagName === "SPAN") {
          node = event.target.parentNode.parentNode;
        } else {
          node = event.target.parentNode;
        }
        console.log(node);
        if (node != "submenu") {
          let menuitem = document.getElementsByClassName("p-menuitem");
          for (let i = 0; i < menuitem.length; i++) {
            menuitem[i].classList.remove("active");
          }
          node.classList.add("active");
        }

        // let node;
        // if (event.target.tagName === "A") {
        //   node = event.target;
        // } else {
        //   node = event.target.parentNode;
        // }
        // let menuitem = document.getElementsByClassName("ui-menuitem-link");
        // for (let i = 0; i < menuitem.length; i++) {
        //   menuitem[i].classList.remove("active");
        // }
        // node.classList.add("active")
      }
}
