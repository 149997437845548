// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  api: {
    // apiEndpoint: 'http://localhost/cmudeptnote-api/api/',
    // apiEndpoint: 'http://localhost:57831/api/' ,
    apiEndpoint: 'https://financeapi.mis.cmu.ac.th/api/',
    authCode: 'Y211bWlzOkNtdVsxMjM0NV0='
  },
  oauth: {
    loginURI: 'https://oauth.cmu.ac.th/v2/Authorize.aspx?',
    // redirectURI: 'http://localhost:4200/oauth',
    // redirectURI: 'http://localhost/cmudeptnote/oauth' ,
    redirectURI: 'https://finance.mis.cmu.ac.th/oauth' ,
    clientID: 'Ar5PNH6DBcfNagKHDSQXKxxBA8jycVENguZyGmud',
    scope: 'cmuitaccount.basicinfo'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
