import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig, APP_CONFIG } from "../../app-config.module";
import { CmuDeptnoteApi } from '../../model/cmu-dept-note-api.model';

@Injectable()
export class DocumentListFacultyService {
    apiEndpoint = '';
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        this.apiEndpoint = `${this.config.app.api.apiEndpoint}`;
    }
    GetDebtnoteSendByOrganization(year: number,organizationId:string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/Org/${organizationId}/Send`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     GetDebtnoteRetrunByOrganization(year: number,organizationId:string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/Org/${organizationId}/retrun`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }

     GetDebtnoteReceiveByOrganization(year: number,organizationId:string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/Org/${organizationId}/Receive`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }



}