import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';
import { map } from 'rxjs/operators';
import { CmuDeptnoteApiParams } from 'src/app/model/cmu-dept-note-api-params.model';
import { RegisterSendDocument } from 'src/app/model/registerSendDocument.model';
@Injectable()
export class ReceiveDocumentService {
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}
    getMaxRegisterNumber(year: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/MaxRegisterID`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    registerSendDocument(registerSendDocument: RegisterSendDocument) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/RegisterSendDocument`;
        const body = new CmuDeptnoteApiParams( registerSendDocument , '');
        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));

    }
}
