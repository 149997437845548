import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportService {
    apiEndpoint = '';
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        this.apiEndpoint = `${this.config.app.api.apiEndpoint}`;
    }
    GetReportDocumentCover(
        FileType: string,
        Year: string,
        ReceiveNumber: string
        ): Observable<Blob> {
        const url = `${this.apiEndpoint}report/GetReportDocumentCover`;
        const queryString =
            `?FileType=${FileType}` +
            `&year=${Year}` +
            `&receiveNumber=${ReceiveNumber}`;
        return this.httpClient
            .get(url + queryString, { responseType: 'blob' })
            .pipe(map(data => data));
    }
    GetReportDocumentCancelCover(
        FileType: string,
        Year: string,
        ReceiveNumber: string
        ): Observable<Blob> {
        const url = `${this.apiEndpoint}report/GetReportDocumentCancelCover`;
        const queryString =
            `?FileType=${FileType}` +
            `&year=${Year}` +
            `&receiveNumber=${ReceiveNumber}`;
        return this.httpClient
            .get(url + queryString, { responseType: 'blob' })
            .pipe(map(data => data));
    }
}
