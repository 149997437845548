import { Injectable , Inject } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { HttpClient } from '@angular/common/http';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';
import { map } from 'rxjs/operators';
import { CmuDeptnoteApiParams } from 'src/app/model/cmu-dept-note-api-params.model';


@Injectable()
export class RegistrationService {
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}
    getMaxDocumentNumber(year: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/DocumentNumber`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // getOrganizationAll() {
    //     const url = `${this.config.app.api.apiEndpoint}organization/all`;
    //     return this.httpClient
    //     .get<CmuDeptnoteApi>(url)
    //     .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    // }
    regisReciveNo(deptnoteId: number, organizationId: string, year: string, receiveNo: string , staffId: string ) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/registrationDoc`;
        const body = new CmuDeptnoteApiParams( {
            debt_note_id: deptnoteId,
            org_code_id: organizationId,
            dept_note_year: year,
            receive_no: receiveNo,
            staffID: staffId
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
     }
     sendDocToStaff(deptnoteId: number, RecipientUserId: string, OwnerUserId: string , docStatus: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SendDocToStaff`;
        const body = new CmuDeptnoteApiParams( {
            debt_note_id: deptnoteId,
            recipient_user_id: RecipientUserId,
            owner_user_id: OwnerUserId,
            doc_status: docStatus

        }, '');
        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
     }
     GetStaff() {
        const url = `${this.config.app.api.apiEndpoint}Staff`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
     }
     getSendTo() {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/SendTo`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }


}

