import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginAdminService } from './login-admin.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-login-admin',
    templateUrl: './login-admin.component.html'
    // styleUrls: ['./loginAdmin.component.scss']
})
export class LoginAdminComponent implements OnInit {
    loginForm: FormGroup;
    constructor(
        private loginAdminServive: LoginAdminService,
        private router: Router
    ) {
        this.initForm();
     }

    ngOnInit(): void { }
    initForm() {
        this.loginForm = new FormGroup({
            username: new FormControl(null),
            CitizenId: new FormControl(null)
        });
    }
    login() {
        const fv = this.loginForm.value;
        this.loginAdminServive.verifyLogin(fv.username).subscribe(res => {
                if (res.status.toLowerCase() === 'true') {
                  // console.log(res.data);
                  localStorage.setItem('jwtToken', res.data.JWTToken);
                  localStorage.setItem(
                    'currentUser',
                    JSON.stringify(res.data.BasicInfo)
                  );
                  // localStorage.setItem('UserInfoData', JSON.stringify(res.data.BasicInfo));
                  localStorage.setItem('RoleUser', JSON.stringify(res.data.RoleUser));
                  if (res.data.RoleUser.RoleID === 1) {
                    this.router.navigate(['./home/admin']);
                  } else if (res.data.RoleUser.RoleID === 3) {
                    this.router.navigate(['./home/main']);
                  } else if (res.data.RoleUser.RoleID === 2) {
                    this.router.navigate(['./home/admin']);
                  } else {
                    this.router.navigate(['./home/page']);
                  }
                } else {
                  this.router.navigate(['./login']);
                }
              },
              error => this.router.navigate(['./login'])
        );

    }
}
