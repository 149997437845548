import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';


@Injectable()
export class InformationService {
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
      ) { }
      getDebtnoteByDateByEmpID(OrganizationID: string, startdate: string, enddate: string, ownerID: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${OrganizationID}/${startdate}/${enddate}/${ownerID}/List`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
      }
      getDebtnoteByDate(OrganizationID: string, startdate) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${OrganizationID}/${startdate}/List`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
      }
      getDebtnoteByDateOrSubjectType(OrganizationID: string, startdate: string, subjectTypeId: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${OrganizationID}/${startdate}/${subjectTypeId}/List`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
      }
      reportDebtnoteReturnApprove(OrganizationID:string ,dateApprove: string ,startPeriod:string ,endPeriod:string,year:number,flagDateApprove:boolean,flagDatePeriod:boolean){
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReportApprove/Org/${OrganizationID}/ApproveDate/${dateApprove}/Period/${startPeriod}/${endPeriod}/Year/${year}/flag/${flagDateApprove}/${flagDatePeriod}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
      }
      reportDebtnoteReturnEdit(OrganizationID:string ,dateApprove: string ,startPeriod:string ,endPeriod:string,year:number,flagDateEdit:boolean,flagDatePeriod:boolean){
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReportEdit/Org/${OrganizationID}/ApproveDate/${dateApprove}/Period/${startPeriod}/${endPeriod}/Year/${year}/flag/${flagDateEdit}/${flagDatePeriod}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
      }
      reportDebtnoteReturnDocument(OrganizationID:string ,dateApprove: string ,startPeriod:string ,endPeriod:string,year:number,flagDateDocument:boolean,flagDatePeriod:boolean){
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReportDocument/Org/${OrganizationID}/ApproveDate/${dateApprove}/Period/${startPeriod}/${endPeriod}/Year/${year}/flag/${flagDateDocument}/${flagDatePeriod}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
      }
      reportDebtnoteReturnCancel(OrganizationID:string ,dateApprove: string ,startPeriod:string ,endPeriod:string,year:number,flagDateCancel:boolean,flagDatePeriod:boolean){
        const url = `${this.config.app.api.apiEndpoint}Debtnote/ReportCancel/Org/${OrganizationID}/ApproveDate/${dateApprove}/Period/${startPeriod}/${endPeriod}/Year/${year}/flag/${flagDateCancel}/${flagDatePeriod}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
      }
}
