import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from 'src/app/app-config.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';
import { CmuDeptnoteApiParams } from 'src/app/model/cmu-dept-note-api-params.model';

@Injectable()
export class AcceptDocumentService {
    apiEndpoint = '';
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}
    getDeptnoteListByParameter(year: string, orgCodeId: number, statusId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/${orgCodeId}/${statusId}/List`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getDeptnoteListByReceiveNo(year: string, receiveNo: string, statusId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/${receiveNo}/${statusId}/SearchReceive`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    acceptDebtnoteDocument(DocId: number, DebtnoteId: number, DocNumber: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/acceptDocument`;
        const body = new CmuDeptnoteApiParams( {
            doc_detail_id: DocId ,
            debt_note_id: DebtnoteId ,
            doc_number: DocNumber
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    // tslint:disable-next-line:max-line-length
    replyDebtnoteDocument(DocId: number, DebtnoteId: number, DocNumber: string, Description: string, supervisorName: string , supervisetPosition: string, year: string, typeNoteReturn: boolean,  Phonenumber: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/replyDebtnoteDocument`;
        const body = new CmuDeptnoteApiParams( {
            doc_detail_id: DocId ,
            debt_note_id: DebtnoteId ,
            doc_number: DocNumber,
            description: Description,
            supervisor_name: supervisorName,
            supervisor_position: supervisetPosition,
            doc_year: year,
            type_note:  typeNoteReturn,
            phonenumber: Phonenumber
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    // tslint:disable-next-line:max-line-length
    replyCancelDebtnoteDocument(DocId: number, DebtnoteId: number, DocNumber: string, Description: string, supervisorName: string , supervisetPosition: string , year: string, lastMessageId: number, lastMessageTxt: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/replyCancelDebtnoteDocument`;
        const body = new CmuDeptnoteApiParams( {
            doc_detail_id: DocId ,
            debt_note_id: DebtnoteId ,
            doc_number: DocNumber,
            description: Description,
            supervisor_name: supervisorName,
            supervisor_position: supervisetPosition,
            doc_year: year,
            last_message_id: lastMessageId,
            last_message_txt: lastMessageTxt
        }, '');
        return this.httpClient.put<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
    getDebtnoteDocumentById(debtnoteId: number , statusId: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${debtnoteId}/status/${statusId}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getCommandStatus() {
        const url = `${this.config.app.api.apiEndpoint}CommandStatus`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }

    getSupervisor() {
        const url = `${this.config.app.api.apiEndpoint}Supervisor`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    GetReportSegmentIncomeSummary(
        FileType: string,
        Year: string,
        ReceiveNumber: string
        ): Observable<Blob> {
        const url = `${this.apiEndpoint}report/GetReportDocumentCover`;
        const queryString =
            `?FileType=${FileType}` +
            `&year=${Year}` +
            `&receiveNumber=${ReceiveNumber}`;
        return this.httpClient
            .get(url + queryString, { responseType: 'blob' })
            .pipe(map(data => data));
    }

}

