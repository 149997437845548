import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';

@Component({
    selector: 'app-side-menu-faculty',
    templateUrl: './side-menu-faculty.component.html'
})
export class AppSideMenuFacultyComponent implements OnInit {
    items: MenuItem[];
    activeItem: MenuItem;
    notiMain: string;
    notiAll:string;
    constructor(
        public app: AppComponent
    ) { }

    ngOnInit(): void {
        this.notiMain ="2";
        this.notiAll = "6";
        // this.items = [
        //     {label: 'หน้าหลัก', icon: 'fa fa-fw fa-bar-chart', routerLink: '/home/main'},
        //     {label: 'สร้างหนังสือนำส่งกองคลัง', icon: 'fa fa-fw fa-calendar', routerLink: '/home/addDocumentSend'},
        //     {label: 'รายการหนังสือตามสถานะ', icon: 'fa fa-fw fa-book', routerLink: '/home/registration', visible: true },
        //     {label: 'ค้นหาหนังสือ', icon: 'fa fa-fw fa-support', routerLink: '/home/homeFaculty'},
        //     {label: 'รายงาน', icon: 'fa fa-fw fa-twitter', routerLink: '/home/registration'}
        // ];
        // this.activeItem = this.items[2];
    }
    // onClickMenu(): void {
    //     $('li.m-menu__item.m-menu__item--submenu.m-menu__item--rel').removeClass([
    //       'm-menu__item--open-dropdown',
    //       'm-menu__item--hover'
    //     ]);
    // }
}
