import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';


@Component({
    selector: 'app-side-menu',
    templateUrl: 'side-menu.component.html'
})

export class SideMenuComponent implements OnInit {
    items: MenuItem[];
    activeItem: MenuItem;
    constructor(
        public app: AppComponent
    ) { }
    ngOnInit() {
        // this.items = [
        //     {label: 'หน้าหลัก', icon: 'fa fa-fw fa-bar-chart', routerLink: '/home/main'},
        //     {label: 'สร้างหนังสือนำส่งกองคลัง', icon: 'fa fa-fw fa-calendar', routerLink: '/home/addDocumentSend'},
        //     {label: 'รายการหนังสือตามสถานะ', icon: 'fa fa-fw fa-book', routerLink: '/home/registration' },
        //     {label: 'ค้นหาหนังสือ', icon: 'fa fa-fw fa-support', routerLink: '/home/homeFaculty'},
        //     {label: 'รายงาน', icon: 'fa fa-fw fa-twitter', routerLink: '/home/registration'}
        // ];
        // this.activeItem = this.items[2];
     }
}
