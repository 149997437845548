
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicInfo } from '../../auth/oauth/basic-info.model';
import { HomeService } from '../../page/home.service';

// import { OAuthService } from './oauth.service';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  encapsulation: ViewEncapsulation.None
})
export class OAuthComponent implements OnInit {
  basicInfo: BasicInfo = new BasicInfo();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    // console.log(queryParams);
    if (queryParams.error) {
      this.router.navigate(['./login']);
    } else {
      this.homeService.getJWTToken(queryParams.code).subscribe(
        res => {
          if (res.status.toLowerCase() === 'true') {
            // console.log(res.data);
            localStorage.setItem('jwtToken', res.data.JWTToken);
            localStorage.setItem('currentUser',JSON.stringify(res.data.BasicInfo));
            // localStorage.setItem('UserInfoData', JSON.stringify(res.data.BasicInfo));
            localStorage.setItem('RoleUser', JSON.stringify(res.data.RoleUser));
            if (res.data.RoleUser.RoleID === 1) {
              this.router.navigate(['./home/admin']);
            } else if (res.data.RoleUser.RoleID === 3) {
              this.router.navigate(['./home/main']);
            } else if (res.data.RoleUser.RoleID === 2) {
              this.router.navigate(['./home/admin']);
            } else {
              this.router.navigate(['./home/page']);
            }
            // localStorage.setItem(
            //   'userInfo',
            //   JSON.stringify(res.data.UserFullInfo)
            // );
          } else {
            this.router.navigate(['./login']);
          }
        },
        error => this.router.navigate(['./login'])
      );
    }
  }
}
