import { HttpClient } from '@angular/common/http';
import { Injectable , Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';


@Injectable()
export class HomeFacultyService {
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}
    getOrganizationAll() {
        const url = `${this.config.app.api.apiEndpoint}organization/all`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getDeptnoteByOrganizationId(OrganizationID: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${OrganizationID}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getDebtnoteByOrganizationAndDate(OrganizationID: string, startdate: string, enddate: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${OrganizationID}/${startdate}/${enddate}/Search`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getDeptnoteByYearAndMonth(year: string, month: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/${month}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getDeptnoteByYearAndReceiveId(year: string, receiveId: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/${receiveId}/ReceiveDocumentList`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // api/Debtnote/{year}/{sendId}/Organization/{organizationId}/sendDocumentList
    getDeptnoteByYearAndRegisId(year: number, receiveId: string, orgId: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${year}/${receiveId}/Organization/${orgId}/sendDocumentList`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    searchReturnDeptnoteByOrganizationId(OrganizationID: string, searchDate: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${OrganizationID}/${searchDate}/Search`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // api/Debtnote/{documentNumber}/{searchDate}/SearchReceiveNo
    searchReturnDeptnoteByDocNumber(docNumber: string , OrganizationID: string, controlNumber: string, years: number) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${docNumber}/${OrganizationID}/${controlNumber}/SearchReceiveNo/${years}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    // tslint:disable-next-line:max-line-length
    searchReceiveReturnDeptnoteByDocNumber(docNumber: string , OrganizationID: string, controlNumber: string, receiveDate, years: number, selectDate: number) {
        // tslint:disable-next-line:max-line-length
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${docNumber}/${OrganizationID}/${controlNumber}/Date/${receiveDate}/${selectDate}/SearchReceiveNo/${years}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getReturnDeptnoteByDocNumber(OrganizationID: string, ReceiveNumber: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/${ReceiveNumber}/${OrganizationID}/ReturnDebtnote`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getDeptnoteByOrganiozation(organizationId: string , year: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${organizationId}/${year}`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
    getDebtnoteApproveGroupByDateApprove(organizationId: string , year: string, receiveNo: string) {
        const url = `${this.config.app.api.apiEndpoint}Debtnote/Organization/${organizationId}/${year}/ReceiveNo/${receiveNo}/Approve`;
        return this.httpClient
        .get<CmuDeptnoteApi>(url)
        .pipe(map(data => CmuDeptnoteApi.fromObject(data)));
    }
}

