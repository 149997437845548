import { CMUDeptnoteApiConfig } from './cmudeptnote-api-config';

export class CMUDeptnoteConfig {
    public api: CMUDeptnoteApiConfig;

    constructor(data?: any) {
        if (data) {
            this.api = data;
        }
    }
}
