import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OAuthService } from '../oauth/oauth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAdminService } from '../login-admin/login-admin.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styles: [` .avatar {
      border-radius: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 96px;
      height: 96px;
    },
    .image{
      height:40em;
      background-size:cover; width:auto;
      background-image:url('copied image address');
      background-position:50% 50%;
      background-repeat: no-repeat;
      background-color: rgb(118, 118, 118);
    }`]
    // background-position:50% 50%;
})
export class LoginComponent implements OnInit {
    keyCode: string;
    itaccount: string;
    background = '59';
    bkUrl: {};
    constructor(
        private loginAdminServive: LoginAdminService,
        private oauthService: OAuthService ,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.initForm();
    }

    ngOnInit(): void {
      this.bkUrl = this.getBkUrl();
    }

    initForm() {
        // this.loginForm = new FormGroup({
        //     username: new FormControl(null, [Validators.required]),
        //     password: new FormControl(null, [Validators.required])
        // });
        this.route.params.subscribe(params => {
            // tslint:disable-next-line:no-string-literal
            this.keyCode  = params['key'];
            console.log(this.keyCode);
            if ( this.keyCode !== undefined) {
                this.itaccount = atob( this.keyCode);
                this.loginAdminServive.verifyLogin(this.itaccount).subscribe(res => {
                    if (res.status.toLowerCase() === 'true') {
                      // console.log(res.data);
                      localStorage.setItem('jwtToken', res.data.JWTToken);
                      localStorage.setItem(
                        'currentUser',
                        JSON.stringify(res.data.BasicInfo)
                      );
                      // localStorage.setItem('UserInfoData', JSON.stringify(res.data.BasicInfo));
                      localStorage.setItem('RoleUser', JSON.stringify(res.data.RoleUser));
                      if (res.data.RoleUser.RoleID === 1) {
                        this.router.navigate(['./home/returnDocument']);
                      } else if (res.data.RoleUser.RoleID === 3) {
                        this.router.navigate(['./home/returnEditdocument']);
                      } else if (res.data.RoleUser.RoleID === 2 ) {
                        this.router.navigate(['./home/returnDocument']);
                      } else {
                        this.router.navigate(['./home/receiveReturnDocument']);
                      }
                    } else {
                      // this.router.navigate(['./login']);
                    }
                  },
                    error => this.router.navigate(['./login'])
                );
            } else {
                this.router.navigate(['./login']);
            }
        });

    }
    getBkUrl() {
      const styles = {
        'background-image': 'url(http://finance.mis.cmu.ac.th/assets/pages/img/background/' + this.background + '.jpg)'
      };
      // console.log(styles);
      return styles;
    }
    sigIn() {
        // console.log('sss');
        this.oauthService.openOAuthSignInPage();
    }
    signInAccount() {
         window.location.href  = './loginAdmin' ;
         // this.router.navigate(['/loginAdmin']);
    }
}


