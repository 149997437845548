export class BasicInfo {
    public cmuitaccount: string;
    public cmuitaccountName: string;
    public firstnameEN: string;
    public firstnameTH: string;
    public itaccounttypeEN: string;
    public itaccounttypeTH: string;
    public itaccounttypeId: string;
    public lastnameEN: string;
    public lastnameTH: string;
    public organizationCode: string;
    public organizationNameEN: string;
    public organizationNameTH: string;
    public prenameEN: string;
    public prenameTH: string;
    public prenameId: string;
    public studentId: string;

    constructor(data?: any) {
        if (data) {
            this.cmuitaccount = data.cmuitaccount;
            this.cmuitaccountName = data.cmuitaccount_name;
            this.firstnameEN = data.firstname_EN;
            this.firstnameTH = data.firstname_TH;
            this.itaccounttypeEN = data.itaccounttype_EN;
            this.itaccounttypeTH = data.itaccounttype_TH;
            this.itaccounttypeId = data.itaccounttype_id;
            this.lastnameEN = data.lastname_EN;
            this.lastnameTH = data.lastname_TH;
            this.organizationCode = data.organization_code;
            this.organizationNameEN = data.organization_name_EN;
            this.organizationNameTH = data.organization_name_TH;
            this.prenameEN = data.prename_EN;
            this.prenameTH = data.prename_TH;
            this.prenameId = data.prename_id;
            this.studentId = data.student_id;
        }
    }
}
