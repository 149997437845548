
import { InjectionToken, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { CMUDeptnoteConfig } from '../app/auth/_models/cmudeptnote-config.model';
import { OauthConfig } from '../app/auth/oauth/oauth-config.model';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  app: CMUDeptnoteConfig;
  oauth: OauthConfig;
  thCalendar: any;
  commandStatusProcess: any;
  monthDropdown: any[];
}

export const APP_DI_CONFIG: AppConfig = {
    app: new CMUDeptnoteConfig({
      apiEndpoint: environment.api.apiEndpoint,
      authCode: environment.api.authCode
    }),
    oauth: new OauthConfig({
      loginURI: environment.oauth.loginURI,
      redirectURI: environment.oauth.redirectURI,
      clientID: environment.oauth.clientID,
      scope: environment.oauth.scope
    }),
    thCalendar: {
      firstDayOfWeek: 0,
      dayNames: [
        'วันอาทิตย์',
        'วันจันทร์',
        'วันอังคาร',
        'วันพุธ',
        'วันพฤหัส',
        'วันศุกร์',
        'วันเสาร์'
      ],
      dayNamesShort: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
      dayNamesMin: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
      monthNames: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
      ],
      monthNamesShort: [
        'ม.ค.',
        'ก.พ.',
        'มี.ค.',
        'เม.ย',
        'พ.ค.',
        'มิ.ย.',
        'ก.ค.',
        'ส.ค.',
        'ก.ย.',
        'ต.ค.',
        'พ.ย.',
        'ธ.ค.'
      ],
      today: 'วันนี้',
      clear: 'ล้างค่า'
    },
    commandStatusProcess: {
      W: 'รอดำเนินการ',
      P: 'กำลังดำเนินการ',
      T: 'สำเร็จ',
      F: 'ไม่สำเร็จ'
    },
    monthDropdown: [
      { label: 'มกราคม', value: 1 },
      { label: 'กุมภาพันธ์', value: 2 },
      { label: 'มีนาคม', value: 3 },
      { label: 'เมษายน', value: 4 },
      { label: 'พฤษภาคม', value: 5 },
      { label: 'มิถุนายน', value: 6 },
      { label: 'กรกฎาคม', value: 7 },
      { label: 'สิงหาคม', value: 8 },
      { label: 'กันยายน', value: 9 },
      { label: 'ตุลาคม', value: 10 },
      { label: 'พฤศจิกายน', value: 11 },
      { label: 'ธันวาคม', value: 12 }
    ]
  };

@NgModule({
    providers: [
      {
        provide: APP_CONFIG,
        useValue: APP_DI_CONFIG
      }
    ]
  })

  export class AppConfigModule { }
