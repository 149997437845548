import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout-content',
    templateUrl: './layout-content.component.html'
})
export class LayoutContentComponent implements OnInit {
    isMenuAdmin = false;
    isMenuFaculty = false;
    isMenuStaff = false;
    isMenu = false;
    constructor(
    ) { }

    ngOnInit(): void {
        const roleAccessUser = JSON.parse(localStorage.getItem('RoleUser'));
        if (roleAccessUser.RoleID === 1) {
            this.isMenuAdmin = true;
        } else if ( roleAccessUser.RoleID === 3) {
           this.isMenu = true;
        } else if ( roleAccessUser.RoleID === 2) {
            this.isMenuStaff = true;
        } else {
            this.isMenuFaculty = true;
        }
    }

}
