import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';
import { CmuDeptnoteApi } from 'src/app/model/cmu-dept-note-api.model';
import { CmuDeptnoteApiParams } from 'src/app/model/cmu-dept-note-api-params.model';
import { Deptnote } from 'src/app/model/deptnote.model';

@Injectable()
export class LoginAdminService {
    constructor(
        private httpClient: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}
    verifyLogin(username: string) {
        const url = `${this.config.app.api.apiEndpoint}verifyLogin`;
        const body = new CmuDeptnoteApiParams(username, '');

        return this.httpClient.post<CmuDeptnoteApi>(url, body).pipe(map(data => data));
    }
}

